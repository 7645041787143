const fontSizes = {
  xxs: '12px',
  xs: '14px',
  sm: '16px',
  base: '18px',
  md: '20px',
  lg: '24px',
  xl: '28px',
  '2xl': '32px',
  '3xl': '36px',
  '4xl': '40px',
  '5xl': '56px',
};

export default fontSizes;
