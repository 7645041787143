import { theme as baseTheme } from '@qga/roo-ui';
import { rem } from 'polished';
import * as icons from './icons';
import colors, { brandColors } from './colors';
import radii from './radii';
import buttons from './buttons';
import fontSizes from './fontSizes';
import { breakpoints, mediaQueries } from './breakPoints';
import textStyles, {
  fontFamily,
  fontFamilyJEL,
  headerFontFamily,
  lineHeights,
} from './textStyles';
import { STICKY_NAV_HEIGHT } from '@/v2/components/pages/PackageViewV2/constants';

const shadows = {
  soft: '0 1px 20px rgba(0, 0, 0, 0.15)',
  hard: '0 1px 2px 0 rgba(0, 0, 0, 0.2);',
  light:
    '0px 0px 4px 0px rgba(0, 0, 0, 0.08), 0px 1px 2px 0px rgba(0, 0, 0, 0.1)',
  // As per JQ Holidays Design System, use these instead of the above
  elevation0: 'rgb(255, 255, 255)',
  elevation1: '0 1px 1px 0 rgba(0, 0, 0, 0.12)',
  elevation1Dark: '0 1px 1px 0 rgba(0, 0, 0, 0.24)',
  elevation2: '0 1px 4px 0 rgba(0, 0, 0, 0.12)',
  elevation6: '0 3px 8px 0 rgba(0, 0, 0, 0.12)',
  elevation8: '0 4px 12px 0 rgba(0, 0, 0, 0.12)',
  elevation12: '0 8px 16px 0px rgba(0, 0, 0, 0.12)',
  elevation24: '0 12px 24px 0px rgba(0, 0, 0, 0.12)',
  elevation32: '0 16px 32px 0px rgba(0, 0, 0, 0.12)',
};

const zIndices = {
  foreground: 1,
  background: 0,
  popup: Number.MAX_SAFE_INTEGER,
};

const mediaCounter = {
  dark: {
    color: colors.mediaCounterButton.dark.color,
    backgroundColor: colors.mediaCounterButton.dark.backgroundColor,
    opacity: 0.6,
    textTransform: 'none',
    letterSpacing: 'normal',
  },
  light: {
    color: colors.mediaCounterButton.light.color,
    backgroundColor: colors.mediaCounterButton.light.backgroundColor,
    opacity: 1,
    textTransform: 'none',
    letterSpacing: 'normal',
  },
};

const maxWidths = {
  default: rem('1272px'),
};

const components = {
  input: {
    backgroundColor: brandColors.white,
    border: baseTheme.borders[1],
    borderColor: colors.greys.smoke,
    disabled: {
      backgroundColor: brandColors.softGrey,
    },
    error: {
      borderColor: colors.ui.error,
    },
    focus: {
      borderColor: colors.brand.secondary,
    },
    label: {
      color: brandColors.charcoal,
      disabled: {
        color: brandColors.steel,
      },
    },
    text: {
      color: brandColors.charcoal,
      disabled: {
        color: brandColors.steel,
      },
    },
    placeholder: {
      color: brandColors.steel,
      disabled: {
        color: brandColors.steel,
      },
    },
    icon: {
      color: brandColors.steel,
      disabled: {
        color: brandColors.steel,
      },
    },
  },
  packageOption: {
    padding: '24px',
    borderWidth: '3px',
    width: '404px',
    borderRadius: '10px',
    gap: '20px',
    travelAnytime: {
      lineHeight: '22px',
      color: colors.primary,
    },
    default: {
      borderColor: 'transparent',
    },
    selected: {
      borderColor: brandColors.grasshopper,
    },
    bestAvailable: {
      backgroundColor: brandColors.orange,
      color: brandColors.white,
    },
    offer: {
      backgroundColor: brandColors.yellow,
      color: brandColors.charcoal,
    },
    pillBackgroundColor: brandColors.white,
  },
  dateRangePicker: {
    disabledMonthNav: colors.greys.smoke,
    daysOfWeek: {
      borderColor: colors.greys.smoke,
    },
    monthName: {
      fontSize: fontSizes.sm,
    },
    weekName: {
      color: colors.text.body,
      fontSize: fontSizes.sm,
    },
    day: {
      borderRadius: radii.rounded,
      borderWidth: 0,
      fontSize: fontSizes.sm,
      fontWeight: 700,
    },
    daySelected: {
      background: colors.background.backdrop,
      color: colors.white,
    },
    potentialDaySelected: {
      borderColor: colors.text.body,
      borderWidth: '1px',
    },
    rangeSelected: {
      background: colors.border,
    },
    unavailableDay: {
      color: colors.disabled,
    },
    tooltip: {
      top: '-49px',
    },
    loadMoreButton: {
      paddingX: baseTheme.space[4],
      paddingY: baseTheme.space[2],
      borderRadius: radii.sm,
      borderColor: colors.greys.smoke,
      borderColorHover: colors.greys.smoke,
      color: colors.ui.link,
      colorHover: colors.ui.linkHover,
      disabled: colors.disabled,
      backgroundHover: 'transparent',
      fontSize: fontSizes.xs,
      fontWeight: 700,
      lineHeight: '1.25rem',
      textTransform: 'none',
    },
  },
  travelDatesCalendar: {
    background: colors.white,
    borderColor: colors.border,
    closeButton: {
      padding: baseTheme.space[4],
    },
    actions: {
      borderColor: colors.border,
      borderWidth: '1px',
    },
    actionConfirm: {
      paddingX: baseTheme.space[4],
      paddingY: baseTheme.space[2],
      width: '7.5rem',
      borderRadius: radii.sm,
      background: colors.ui.link,
      backgroundHover: colors.ui.linkHover,
      color: colors.white,
      colorHover: colors.white,
      fontSize: fontSizes.xs,
      fontWeight: 700,
      lineHeight: '1.25rem',
      textTransform: 'none',
    },
    actionReset: {
      color: colors.ui.link,
      colorHover: colors.ui.linkHover,
      fontSize: fontSizes.sm,
      fontWeight: 700,
    },
    selectHeader: {
      fontSize: fontSizes.base,
      color: colors.greys.charcoal,
    },
    selectText: {
      fontSize: fontSizes.xs,
      color: colors.text.body,
    },
  },
  select: {
    fullScreenMenu: {
      borderRadius: 'max(0px, min(6px, calc((100vh - 100%) * 9999)))',
    },
    icon: {
      size: '32px',
    },
    scrollable: {
      padding: baseTheme.space[4],
      gap: baseTheme.space[2],
      mobile: {
        padding: baseTheme.space[4],
        gap: baseTheme.space[2],
      },
    },
    menuLabel: {
      fontWeight: 600,
      fontSize: fontSizes.base,
      lineHeight: baseTheme.space[8],
      color: colors.text.heading,
      textAlign: 'left',
    },
    menuItem: {
      color: colors.text.heading,
      backgroundHover: colors.background.hover,
      borderLeftColorActive: 'transparent',
      paddingY: baseTheme.space[2],
      paddingX: baseTheme.space[2],
      fontWeightHover: 400,
      fontSize: fontSizes.sm,
      lineHeight: '1.5',
    },
    size: {
      sm: {
        height: '40px',
        fontSize: fontSizes.xs,
        paddingLeft: '20px',
        paddingRight: '13px',
        mobile: {
          height: '40px',
          fontSize: fontSizes.xs,
          paddingLeft: baseTheme.space[4],
          paddingRight: baseTheme.space[3],
        },
      },
      md: {
        height: '48px',
        fontSize: fontSizes.sm,
        paddingLeft: '36px',
        paddingRight: '28px',
        mobile: {
          height: '48px',
          fontSize: fontSizes.sm,
          paddingLeft: baseTheme.space[5],
          paddingRight: baseTheme.space[4],
        },
      },
      lg: {
        height: '56px',
        fontSize: fontSizes.base,
        paddingLeft: '36px',
        paddingRight: '28px',
        mobile: {
          height: '48px',
          fontSize: fontSizes.sm,
          paddingLeft: baseTheme.space[5],
          paddingRight: baseTheme.space[4],
        },
      },
    },
    backgroundHover: colors.background.hover,
    background: colors.background.card,
    borderColorActive: colors.icon.standAlone,
    borderColor: colors.border,
    borderColorError: colors.ui.error,
    borderActive: '3px solid',
    border: '2px solid',
    borderRadius: '28px',
    colorHover: colors.text.heading,
    color: colors.text.heading,
    marginActive: '0',
    margin: '0px 1px',
    fontWeight: 600,
    fontWeightHover: 600,
    fontSize: fontSizes.sm,
  },
  collapsiblePackageSection: {
    gap: {
      column: '24px',
      row: '24px',
      mobile: {
        column: '16px',
        row: '16px',
      },
    },
    padding: {
      vertical: '32px',
      mobile: {
        vertical: '24px',
      },
    },
    background: {
      closed: colors.white,
      open: colors.background.page,
    },
    heading: {
      big: {
        fontFamily: headerFontFamily,
        fontSize: fontSizes.lg,
        lineHeight: '1.25',
        fontWeight: 'bold',
        color: colors.text.heading,
        mobile: {
          fontSize: fontSizes.base,
          lineHeight: '22px',
        },
      },
      small: {
        fontFamily: headerFontFamily,
        fontSize: fontSizes.sm,
        lineHeight: '1.25',
        fontWeight: 'bold',
        color: colors.text.body,
        mobile: {
          fontSize: fontSizes.xs,
          lineHeight: '18px',
        },
      },
      gap: '4px',
    },
  },
  searchPanel: {
    backgroundColor: brandColors.white,
    top: `${STICKY_NAV_HEIGHT + 16}px`,
  },
  selectableContainer: {
    color: colors.icon.success,
    iconSize: '24px',
  },
  tooltip: {
    arrow: {
      offset: 16,
      width: 10,
      height: 6,
    },
    container: {
      borderColor: colors.greys.smoke,
      boxShadow: shadows.elevation8,
      color: colors.white,
    },
    text: {
      color: colors.text.heading,
      fontFamily: fontFamilyJEL,
      fontSize: fontSizes.xxs,
      lineHeight: '18px',
    },
  },
};

const theme = {
  ...baseTheme,
  ...{
    components,
    colors,
    icons,
    fontSizes,
    buttons,
    textStyles,
    lineHeights,
    shadows,
    zIndices,
    radii,
    fontFamily,
    headerFontFamily,
    breakpoints,
    mediaCounter,
    mediaQueries,
    maxWidths,
  },
  brand: 'jetstar',
};

export default theme;
