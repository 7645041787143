import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';

const ELEMENTS = [
  'input:not([disabled])',
  'select:not([disabled])',
  'textarea:not([disabled])',
  'button:not([disabled])',
  'a',
];

const trapTabKey = (firstTabStop, lastTabStop) => (e) => {
  // Check for TAB key press
  if (e.keyCode === 9) {
    // SHIFT + TAB
    if (e.shiftKey) {
      if (document.activeElement === firstTabStop) {
        e.preventDefault();
        lastTabStop.focus();
      }

      // TAB
    } else if (document.activeElement === lastTabStop) {
      e.preventDefault();
      firstTabStop.focus();
    }
  }
};

const FocusTrap = ({ className, children }) => {
  const keydownHandlerRef = useRef();
  const focusedElementRef = useRef();

  const effect = useCallback((element) => {
    if (!element) {
      focusedElementRef.current.focus();
      window.removeEventListener('keydown', keydownHandlerRef.current);
      return;
    }

    focusedElementRef.current = document.activeElement;

    const elements = [...element.querySelectorAll(ELEMENTS.join(','))];
    if (!elements.length) {
      return;
    }

    const firstTabStop = elements[0];
    const lastTabStop = elements[elements.length - 1];

    firstTabStop.focus();

    keydownHandlerRef.current = trapTabKey(firstTabStop, lastTabStop);

    window.addEventListener('keydown', keydownHandlerRef.current);
  }, []);

  return (
    <div className={className ?? ''} ref={effect}>
      {children}
    </div>
  );
};

FocusTrap.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default FocusTrap;
