import { Button as BaseButton } from '@qga/roo-ui/components';
import styled from '@emotion/styled';
import { themeGet, variant } from 'styled-system';
import { css } from '@emotion/core';
import { SCREEN_SIZE } from '../../lib/theme/breakPoints';

const buttonStyle = variant({ key: 'buttons' });
const getBackgroundColor = (props) =>
  (buttonStyle(props) || {}).backgroundColor;
const getColor = (props) => (buttonStyle(props) || {}).color;

const defaultStyle = (props) => css`
  font-size: ${themeGet('fontSizes.sm')(props)};
  line-height: 20px;
  border-radius: ${themeGet('radii.lg')(props)};
  padding: ${themeGet('space.3')(props)} ${themeGet('space.7')(props)};

  ${SCREEN_SIZE.SM} {
    border-radius: ${themeGet('radii.xl')(props)};
    padding: ${themeGet('space.3')(props)} ${themeGet('space.9')(props)};
  }
`;

const convertToSecondary = (props) => css`
  ${themeGet('buttons.secondary_outline')(props)}
`;

const convertSupporting = (props) => css`
  border-radius: ${themeGet('radii.xs')(props)};
  padding: ${themeGet('space.2')(props)} ${themeGet('space.6')(props)};

  ${SCREEN_SIZE.SM} {
    border-radius: ${themeGet('radii.sm')(props)};
    padding: ${themeGet('space.3')(props)} ${themeGet('space.10')(props)};
  }
`;

const convertToLarge = (props) => css`
  font-size: ${themeGet('fontSizes.sm')(props)};
  line-height: ${themeGet('lineHeights.loose')(props)};
  padding: ${themeGet('space.3')(props)} ${themeGet('space.9')(props)};

  ${SCREEN_SIZE.SM} {
    font-size: ${themeGet('fontSizes.md')(props)};
    line-height: 24px;
    padding: ${themeGet('space.5')(props)} ${themeGet('space.12')(props)};
  }
`;

const convertToOutline = (props) => css`
  border: 2px solid;
  border-color: ${!props.inverse
    ? getBackgroundColor(props)
    : themeGet('colors.white')(props)};
  color: ${!props.inverse
    ? getBackgroundColor(props)
    : themeGet('colors.white')(props)};
  background-color: transparent;
  padding: ${themeGet('space.3')(props)} ${themeGet('space.6')(props)};
  will-change: background-color, color;
  transition:
    background-color 250ms cubic-bezier(0, 0, 0.4, 1),
    color 250ms cubic-bezier(0, 0, 0.4, 1);

  &:hover,
  &:focus {
    color: ${getColor(props)};
    border-color: transparent;
  }

  &:hover:disabled,
  &:focus:disabled {
    color: ${getBackgroundColor(props)};
    background-color: transparent;
  }

  ${SCREEN_SIZE.SM} {
    padding: ${themeGet('space.3')(props)} ${themeGet('space.8')(props)};
  }
`;

const convertToFlight = (props) => css`
  ${convertToOutline(props)};
  border-color: ${themeGet('colors.brand.primary')(props)};
  color: ${themeGet('colors.brand.primary')(props)};
  font-size: ${themeGet('fontSizes.xs')(props)};
  padding: ${themeGet('space.2')(props)} ${themeGet('space.6')(props)};
  text-transform: initial;
  letter-spacing: 0;

  &:hover,
  &:focus {
    color: ${themeGet('colors.white')(props)};
    background-color: ${themeGet('colors.brand.primary')(props)};
  }

  ${SCREEN_SIZE.SM} {
    padding: ${themeGet('space.2')(props)} ${themeGet('space.3')(props)};
  }
`;

const convertToDialogue = (props) => css`
  border-radius: ${themeGet('radii.sm')(props)};
  padding: ${themeGet('space.3')(props)} ${themeGet('space.6')(props)};

  ${SCREEN_SIZE.SM} {
    border-radius: ${themeGet('radii.sm')(props)};
    padding: ${themeGet('space.3')(props)} ${themeGet('space.6')(props)};
  }
`;

const convertToFullWidth = () => css`
  width: 100%;

  ${SCREEN_SIZE.SM} {
    width: unset;
  }
`;

const convertToInverse = (props) => css`
  border-color: ${themeGet('colors.white')(props)};
`;

const convertToNowrap = () => css`
  white-space: nowrap;
`;

const convertToAuxiliary = (props) => css`
  ${themeGet('buttons.auxiliary')(props)}
`;

const convertToNoBorder = () => css`
  border: none;
`;

const Button = styled(BaseButton)`
  ${(props) => defaultStyle(props)}
  ${(props) =>
    props.variant === 'secondary' &&
    !props.outline &&
    !props.dialogue &&
    convertToSecondary(props)}
  ${(props) => props.variant === 'supporting' && convertSupporting(props)}
  ${(props) => props.variant === 'auxiliary' && convertToAuxiliary(props)}
  ${(props) => props.variant === 'flight' && convertToFlight(props)}
  ${(props) => props.large && convertToLarge(props)}
  ${(props) => props.outline && convertToOutline(props)}
  ${(props) => props.dialogue && convertToDialogue(props)}
  ${(props) => props.fullWidth && convertToFullWidth()}
  ${(props) => props.inverse && convertToInverse(props)}
  ${(props) => props.nowrap && convertToNowrap()}
  ${(props) => props.noBorder && convertToNoBorder()}
`;

Button.propTypes = {
  ...BaseButton.propTypes,
  ...variant.propTypes,
};

Button.defaultProps = {
  ...BaseButton.defaultProps,
  variant: 'primary',
};

export default Button;
