import { NakedButton } from '@qga/roo-ui/components';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { themeGet } from 'styled-system';
import { SCREEN_SIZE } from '../../lib/theme/breakPoints';

const defaultStyle = (props) => css`
  font-size: ${themeGet('fontSizes.xs')(props)};
  line-height: 1rem;
  padding: ${props.padding} || ${themeGet('space.2')(props)};
  &:hover {
    color: ${themeGet('colors.text.hover')(props)};
  }
  ${SCREEN_SIZE.SM} {
    padding: ${props.padding} || ${themeGet('space.3')(props)};
    font-size: ${themeGet('fontSizes.sm')(props)};
    line-height: ${themeGet('lineHeights.tight')(props)};
  }
`;

const removePadding = () => css`
  padding: 0;
  ${SCREEN_SIZE.SM} {
    padding: 0;
  }
`;

const removeVerticalPadding = () => css`
  padding-top: 0;
  padding-bottom: 0;

  ${SCREEN_SIZE.SM} {
    padding-top: 0;
    padding-bottom: 0;
  }
`;

const setFullWidth = () => css`
  width: 100%;
`;

const hover = () => css`
  &:focus,
  &:hover {
    color: inherit;
    text-decoration: underline;
  }
`;

const LinkButton = styled(NakedButton)`
  ${(props) => defaultStyle(props)}
  ${(props) => props.noPadding && removePadding()}
  ${(props) => props.noVerticalPadding && removeVerticalPadding()}
  ${(props) => props.fullWidth && setFullWidth()}
  ${(props) => props.hover && hover()}
`;

LinkButton.defaultProps = {
  ...NakedButton.defaultProps,
  color: 'secondary',
  textStyle: 'link',
};

export default LinkButton;
