import { Text as BaseText } from '@qga/roo-ui/components';
import styled from '@emotion/styled';
import { themeGet } from 'styled-system';
import { css } from '@emotion/core';
import { SCREEN_SIZE } from '../../lib/theme/breakPoints';

const convertToBold = (props) => css`
  font-weight: ${themeGet('fontWeights.bold')(props)};
`;

const convertToHint = (props) => css`
  color: ${themeGet('colors.text.hint')(props)};
`;

const convertToLink = (props) => css`
  color: ${themeGet('colors.secondary')(props)};
  &:hover {
    color: ${themeGet('colors.text.hover')(props)};
  }
`;

const convertToSmall = (props) => css`
  font-size: ${themeGet('fontSizes.xxs')(props)};
`;

const convertToMedium = (props) => css`
  font-size: ${themeGet('fontSizes.xs')(props)};

  ${SCREEN_SIZE.SM} {
    font-size: ${themeGet('fontSizes.sm')(props)};
  }
`;

const convertToHuge = (props) => css`
  font-size: ${themeGet('fontSizes.lg')(props)};
  line-height: 1.2;

  ${SCREEN_SIZE.SM} {
    font-size: ${themeGet('fontSizes.2xl')(props)};
    line-height: ${themeGet('fontSizes.3xl')(props)};
  }
`;

const Text = styled(BaseText)`
  ${(props) => props.bold && convertToBold(props)}
  ${(props) => props.hint && convertToHint(props)}
  ${(props) => props.link && convertToLink(props)}
  ${(props) => props.small && convertToSmall(props)}
  ${(props) => props.medium && convertToMedium(props)}
  ${(props) => props.huge && convertToHuge(props)}
`;

Text.defaultProps = {
  textStyle: 'paragraph',
};

export default Text;
