import { css, Global } from '@emotion/core';
import getAsset from '@/utils/getAsset';

const Roboto = () => {
  return (
    <Global
      styles={css`
        @font-face {
          font-family: Roboto;
          src: url(${getAsset('/fonts/Roboto-Regular.ttf')}) format('truetype');
          font-style: normal;
          font-weight: 400;
          font-display: swap;
        }

        @font-face {
          font-family: Roboto;
          src: url(${getAsset('/fonts/Roboto-Bold.ttf')}) format('truetype');
          font-style: normal;
          font-weight: 700;
          font-display: swap;
        }
      `}
    />
  );
};

export default Roboto;
