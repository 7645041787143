import React from 'react';
import { Box, Container, Flex, NakedButton } from '@qga/roo-ui/components';
import useCopyHeight from '@/hooks/useCopyHeight';
import usePreview from '@experiences-ui/shared/v2/hooks/usePreview';
import Portal from '@experiences-ui/shared/v2/components/Portal';

const GlobalPreviewIndicator = () => {
  const { height, heightEffect } = useCopyHeight();
  const { preview, loading, clearPreview } = usePreview();

  if (!preview) {
    return null;
  }

  return (
    <Box height={height}>
      <Portal selector="globalPreviewIndicator-root">
        <Box
          ref={heightEffect}
          position="fixed"
          px="4"
          py="2"
          top="0"
          right="0"
          left="0"
          bg="ui.errorBackground"
        >
          <Container>
            <Flex alignItems="center">
              <Box>
                Now Previewing. You can now navigate to the page you wish to
                preview.
              </Box>
              <Box ml="auto" fontSize="sm" color="greys.steel">
                <NakedButton onClick={clearPreview} disabled={loading}>
                  Leave Preview Mode
                </NakedButton>
              </Box>
            </Flex>
          </Container>
        </Box>
      </Portal>
    </Box>
  );
};

export default GlobalPreviewIndicator;
