import { useRouter } from 'next/router';
import { useEffect } from 'react';

export type UseClearDocumentReferrerProps = {
  disable?: boolean;
} | void;

/**
 * @description - This hook is used to clear the `document.referrer` value after
 * a 'virtual' navigation if it is defined. This is to prevent the referrer value
 * persisting its initial value throughout navigations across the entire site which
 * causes misattribution of Google Ads metrics.
 */
const useClearDocumentReferrer = (props: UseClearDocumentReferrerProps) => {
  const { disable } = props || {};
  const { events } = useRouter();

  useEffect(() => {
    if (disable) return;

    const clearReferrer = () => {
      if (!document.referrer) return;

      try {
        Object.defineProperty(document, 'referrer', {
          get: () => '',
        });
      } catch (e) {
        console.error(e);
      }
    };

    events.on('routeChangeStart', clearReferrer);

    return () => {
      events.off('routeChangeStart', clearReferrer);
    };
  }, [disable, events]);
};

export default useClearDocumentReferrer;
