import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Flex } from '@qga/roo-ui/components';
import Link from '@/components/Link';
import Text from '@/components/Text';
import Modal from '@/components/Modal';

const UnexpectedErrorModal = ({ onClose }) => {
  useEffect(() => {
    document.querySelector('#portal-root').innerHTML = '';
  }, []);

  return (
    <Modal
      size="lg"
      onClose={onClose}
      appElement={
        document.querySelector('#__next') || document.querySelector('#root')
      }
      isOpen
    >
      <Modal.Header>Something went wrong</Modal.Header>
      <Modal.Body>
        <Flex flexDirection="column">
          <Text>
            We&apos;re sorry, an unexpected error occurred while we were
            processing your request. Please try again, otherwise{' '}
            <Link.Text external href="/contact-us">
              contact the Jetstar Holidays team
            </Link.Text>{' '}
            for assistance.
          </Text>
        </Flex>
      </Modal.Body>
    </Modal>
  );
};
UnexpectedErrorModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default UnexpectedErrorModal;
