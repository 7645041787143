const radii = {
  xs: '4px',
  sm: '6px',
  md: '10px',
  lg: '32px',
  xl: '40px',
  default: '4px',
  rounded: '40px',
};

export default radii;
