import { css } from '@emotion/core';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import React from 'react';
import { Alert, Flex, Image, Box } from '@qga/roo-ui/components';
import { themeGet } from 'styled-system';
import getAsset from '@/utils/getAsset';

const StyledAlert = styled(Alert)`
  border-radius: ${themeGet('radii.xs')};
  ${(props) => {
    const backgroundColor = `${themeGet(`colors.background.${props.variant}`)(
      props,
    )}`;
    const border = `1px solid ${themeGet(`colors.${props.borderColor}`)(
      props,
    )}`;
    const borderLeft = `4px solid ${themeGet(`colors.${props.borderColor}`)(
      props,
    )}`;
    const justifyContent = props.justifyContent || 'flex-start';
    return css`
      display: flex;
      justify-content: ${justifyContent};
      background-color: ${backgroundColor};
      border: ${border};
      border-left: ${borderLeft};
    `;
  }}
`;

const Info = ({ children, ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <StyledAlert
    icon={null}
    py="0"
    px="2"
    variant="info"
    borderColor="secondary"
    {...props}
  >
    {children}
  </StyledAlert>
);

const Success = ({ children, withIcon, ...props }) => (
  <StyledAlert
    icon={null}
    py="0"
    px="2"
    variant="success"
    borderColor="success"
    {...props}
  >
    <Flex alignItems="flex-start" m="0">
      {withIcon && (
        <Image
          inline
          src={getAsset('/images/icons/ic_check_circle.svg')}
          alt="Success"
          height="24px"
          mr="4"
        />
      )}
      <Box>{children}</Box>
    </Flex>
  </StyledAlert>
);

const Warning = ({ children, withIcon, ...props }) => (
  <StyledAlert
    icon={null}
    py="0"
    px="2"
    variant="warning"
    borderColor="rating"
    {...props}
  >
    <Flex alignItems="flex-start" m="0">
      {withIcon && (
        <Image
          inline
          src={getAsset('/images/icons/ic_warning.svg')}
          alt="Warning"
          height="20px"
          mt="1"
          mr="4"
        />
      )}
      <Box>{children}</Box>
    </Flex>
  </StyledAlert>
);

const Error = ({ children, withIcon }) => (
  <StyledAlert icon={null} py="0" px="2" variant="error" borderColor="error">
    <Flex alignItems="flex-start" m="0">
      {withIcon && (
        <Image
          inline
          src={getAsset('/images/icons/ic_error.svg')}
          alt="Error"
          height="20px"
          mt="1"
          mr="4"
        />
      )}
      <Box>{children}</Box>
    </Flex>
  </StyledAlert>
);

Warning.defaultProps = {
  children: undefined,
  withIcon: true,
};

Warning.propTypes = {
  children: PropTypes.node,
  withIcon: PropTypes.bool,
};

Error.defaultProps = {
  children: undefined,
  withIcon: true,
};

Error.propTypes = {
  children: PropTypes.node,
  withIcon: PropTypes.bool,
};

Success.defaultProps = {
  children: undefined,
  withIcon: true,
};

Success.propTypes = {
  children: PropTypes.node,
  withIcon: PropTypes.bool,
};

Info.defaultProps = {
  children: undefined,
};

Info.propTypes = {
  children: PropTypes.node,
};

Alert.Warning = Warning;
Alert.Error = Error;
Alert.Info = Info;
Alert.Success = Success;

export default Alert;
