import { useCallback, useRef, useState } from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import throttle from '@experiences-ui/shared/v2/utils/throttle';

const useCopyHeight = () => {
  const elementObserverRef = useRef();
  const handleResizeRef = useRef();
  const [height, setHeight] = useState();

  const heightEffect = useCallback((element) => {
    if (!element) {
      const handleResize = handleResizeRef.current;
      window.removeEventListener('resize', handleResize);
      elementObserverRef.current.disconnect();

      return;
    }

    const handleResize = throttle(() => setHeight(element.offsetHeight));

    handleResize();
    window.addEventListener('resize', handleResize);

    if (!elementObserverRef.current) {
      const elementObserver = new ResizeObserver(handleResize);
      elementObserver.observe(element);

      elementObserverRef.current = elementObserver;
    }

    handleResizeRef.current = handleResize;
  }, []);

  return {
    height,
    heightEffect,
  };
};

export default useCopyHeight;
