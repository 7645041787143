import { BASE_PATH } from '@/config';

const enhanceCookieOptions = (options) => {
  if (!options) {
    return undefined;
  }

  const { withoutBasePath, ...enhancedOptions } = options;

  if (options.path && !withoutBasePath) {
    const basePath = BASE_PATH || '/';
    enhancedOptions.path =
      options.path === '/' ? basePath : `${basePath}${options.path}`;
  }

  return enhancedOptions;
};

export default enhanceCookieOptions;
