import { Heading as BaseHeading } from '@qga/roo-ui/components';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

const renderColor = (props) => css`
  color: ${props.color};
`;

const Heading = styled(BaseHeading)`
  ${(props) => props.color && renderColor(props)}
`;
Heading.defaultProps = {
  ...BaseHeading.defaultProps,
  my: 0,
  textStyle: 'heading',
  fontSize: ['xl', null, '5xl'],
  color: BaseHeading.defaultProps.color,
};

Heading.h1 = styled(Heading)`
  ${(props) => props.color && renderColor(props)}
`;
Heading.h1.defaultProps = {
  ...Heading.defaultProps,
  fontSize: ['xl', null, '5xl'],
  as: 'h1',
};

Heading.h2 = styled(Heading)`
  ${(props) => props.color && renderColor(props)}
`;
Heading.h2.defaultProps = {
  ...Heading.defaultProps,
  fontSize: ['lg', null, '4xl'],
  as: 'h2',
};

Heading.h3 = styled(Heading)`
  ${(props) => props.color && renderColor(props)}
`;
Heading.h3.defaultProps = {
  ...Heading.defaultProps,
  fontSize: ['md', null, '2xl'],
  as: 'h3',
};

Heading.h4 = styled(Heading)`
  ${(props) => props.color && renderColor(props)}
`;
Heading.h4.defaultProps = {
  ...Heading.defaultProps,
  fontSize: ['base', null, 'lg'],
  as: 'h4',
};

Heading.h5 = styled(Heading)`
  ${(props) => props.color && renderColor(props)}
`;
Heading.h5.defaultProps = {
  ...Heading.defaultProps,
  fontSize: ['sm', null, 'md'],
  as: 'h5',
};

Heading.h6 = styled(Heading)`
  ${(props) => props.color && renderColor(props)}
`;
Heading.h6.defaultProps = {
  ...Heading.defaultProps,
  fontSize: ['sm', null, 'base'],
  as: 'h6',
};

export default Heading;
