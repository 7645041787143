import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import PageHeaderContext from './PageHeaderContext';

const PageHeaderProvider = ({ children }) => {
  const [visible, setVisible] = useState(true);

  const value = useMemo(
    () => ({
      visible,
      setVisible,
    }),
    [visible],
  );

  return (
    <PageHeaderContext.Provider value={value}>
      {children}
    </PageHeaderContext.Provider>
  );
};

PageHeaderProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PageHeaderProvider;
