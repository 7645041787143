import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex } from '@qga/roo-ui/components';
import Button from '@/components/Button';
import ModalContext from '../../ModalContext';

const Footer = ({ children }) => (
  <ModalContext.Consumer>
    {({ onClose }) => (
      <>
        {children && <Box p={['4', null, '5']}>{children}</Box>}
        {onClose && (
          <Flex justifyContent="flex-end" p={['4', null, '5']}>
            <Button
              variant="secondary"
              dialogue
              fullWidth
              data-testid="CLOSE_BUTTON_FOOTER"
              onClick={() => onClose()}
            >
              Close
            </Button>
          </Flex>
        )}
      </>
    )}
  </ModalContext.Consumer>
);

Footer.defaultProps = {
  children: undefined,
};

Footer.propTypes = {
  children: PropTypes.node,
};

export default Footer;
