import isDataURI from 'validator/lib/isDataURI';
import { ASSET_PREFIX } from '../../config';

const getAsset = (path) => {
  if (!ASSET_PREFIX || isDataURI(path)) {
    return path;
  }

  return `${ASSET_PREFIX}${path}`;
};

export default getAsset;
