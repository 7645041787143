export const BREAK_POINTS_PX = [768, 1100, 1280];

export const breakpoints = BREAK_POINTS_PX.map((bp) => `${bp}px`);

export const mediaQueries = breakpoints.map(
  (n) => `@media screen and (min-width: ${n})`,
);

export const SCREEN_SIZE = {
  SM: mediaQueries[0],
  MD: mediaQueries[1],
  LG: mediaQueries[2],
};
