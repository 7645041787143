import { useClearDocumentReferrer } from '@/libs/v2/utils/googleAds';
import useShouldClearDocumentReferrer from '@/v2/hooks/featureFlags/googleAds/useShouldClearDocumentReferrer';

// Just a component wrapper around the hook since `App` is a class component and
// can't use the hook
const ClearDocumentReferrer = () => {
  const enableClearDocumentReferrer = useShouldClearDocumentReferrer();
  useClearDocumentReferrer({ disable: !enableClearDocumentReferrer });

  return null;
};

export default ClearDocumentReferrer;
