import { theme as baseTheme } from '@qga/roo-ui';
import { brandColors } from './colors';

const fontSizes = {
  letterSpacing: baseTheme.letterSpacings.normal,
  textTransform: 'none',
};

const buttons = {
  primary: {
    ...fontSizes,
    color: brandColors.white,
    backgroundColor: brandColors.orange,
    '&&:focus': {
      backgroundColor: brandColors.sunset,
    },
    '&&:disabled': {
      color: 'rgba(255, 81,21, 0.5)',
      borderColor: 'rgba(255, 81,21, 0.5)',
      backgroundColor: 'transparent',
    },
    '&&:hover:disabled': {
      color: 'rgba(255, 81,21, 0.5)',
    },
  },
  secondary: {
    ...fontSizes,
    color: brandColors.white,
    backgroundColor: brandColors.blue,
    '&&:hover:not(:disabled), &&:focus': {
      backgroundColor: brandColors.cerulean,
    },
  },
  secondary_outline: {
    ...fontSizes,
    border: '2px solid',
    borderColor: brandColors.smoke,
    color: brandColors.blue,
    backgroundColor: brandColors.white,
    '&&:hover:not(:disabled), &&:focus': {
      borderColor: brandColors.blue,
      color: brandColors.white,
      backgroundColor: brandColors.blue,
    },
    '&&:disabled': {
      display: 'none',
    },
  },
  supporting: {
    ...fontSizes,
    border: '2px solid',
    borderColor: brandColors.smoke,
    color: brandColors.blue,
    backgroundColor: brandColors.white,
    '&&:hover:not(:disabled), &&:focus': {
      borderColor: brandColors.grey,
      color: brandColors.cerulean,
      backgroundColor: brandColors.white,
    },
  },
  auxiliary: {
    ...fontSizes,
    border: '2px solid',
    borderColor: brandColors.smoke,
    color: brandColors.blue,
    backgroundColor: 'transparent',
    '&:hover, &:focus-visible': {
      color: brandColors.cerulean,
    },
    '&:focus': {
      boxShadow: 'none',
    },
    '&:focus-visible': {
      outline: `2px solid ${brandColors.blue}`,
      outlineOffset: '2px',
    },
  },
};

export default buttons;
