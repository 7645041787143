import React from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';

const DocumentTitle = ({ children }) => (
  <Head>
    <title>
      {children && `${children} | `}
      Jetstar Holidays
    </title>
  </Head>
);

DocumentTitle.defaultProps = {
  children: undefined,
};

DocumentTitle.propTypes = {
  children: PropTypes.string,
};

export default DocumentTitle;
