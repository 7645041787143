import { Box, Icon, Flex } from '@qga/roo-ui/components';
import PropTypes from 'prop-types';
import React from 'react';
import LinkButton from '@/components/LinkButton';
import Heading from '@/components/Heading';
import ModalContext from '../../ModalContext';

const Header = ({ children }) => (
  <ModalContext.Consumer>
    {({ onClose }) => (
      <Box p="4" borderBottom="1" borderColor="border" position="relative">
        <Box mb="0" pr="7">
          <Heading.h4 data-testid="MODAL_HEADER">{children}</Heading.h4>
        </Box>
        {onClose && (
          <Flex
            position="absolute"
            right="0"
            top="0"
            bottom="0"
            pr={['1', null, '3']}
          >
            <LinkButton
              data-testid="CLOSE_BUTTON"
              onClick={() => onClose()}
              p="2"
            >
              <Icon color="icon.inclusion" name="close" />
            </LinkButton>
          </Flex>
        )}
      </Box>
    )}
  </ModalContext.Consumer>
);

Header.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Header;
