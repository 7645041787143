import { PackagePageNavItem } from '../types';

export const PACKAGE_PAGE_NAV_ITEMS: PackagePageNavItem[] = [
  { key: 'photos', title: 'Photos' },
  { key: 'overview', title: 'Overview' },
  { key: 'flyingFrom', title: 'Flying from' },
  { key: 'package', title: 'Package options' },
  { key: 'room', title: 'Room options' },
  { key: 'about', title: 'About the hotel' },
  { key: 'policies', title: 'Hotel policies' },
];

export const STICKY_NAV_HEIGHT = 53;
