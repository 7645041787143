import React from 'react';
import { Global, css } from '@emotion/core';
import getAsset from '../../../utils/getAsset';

const JetstarFont = () => (
  <Global
    styles={css`
      @font-face {
        font-family: 'Jetstar';
        src:
          url(${getAsset('/fonts/jetstar_happy-light_v1.0-webfont.woff2')})
            format('woff2'),
          url(${getAsset('/fonts/jetstar_happy-light_v1.0-webfont.woff')})
            format('woff');
        font-style: normal;
        font-weight: 300;
        text-rendering: optimizeLegibility;
        font-display: block;
      }

      @font-face {
        font-family: 'Jetstar';
        src:
          url(${getAsset('/fonts/jetstar_happy-regular_v1.0-webfont.woff2')})
            format('woff2'),
          url(${getAsset('/fonts/jetstar_happy-regular_v1.0-webfont.woff')})
            format('woff');
        font-style: normal;
        font-weight: 400;
        text-rendering: optimizeLegibility;
        font-display: block;
      }

      @font-face {
        font-family: 'Jetstar';
        src:
          url(${getAsset('/fonts/jetstar_happy-bold_v1.0-webfont.woff2')})
            format('woff2'),
          url(${getAsset('/fonts/jetstar_happy-bold_v1.0-webfont.woff')})
            format('woff');
        font-style: normal;
        font-weight: 700;
        text-rendering: optimizeLegibility;
        font-display: block;
      }

      @font-face {
        font-family: 'Jetstar';
        src:
          url(${getAsset('/fonts/jetstar_happy-heavy_v1.0-webfont.woff2')})
            format('woff2'),
          url(${getAsset('/fonts/jetstar_happy-heavy_v1.0-webfont.woff')})
            format('woff');
        font-style: normal;
        font-weight: 800;
        text-rendering: optimizeLegibility;
        font-display: block;
      }
    `}
  />
);

export default JetstarFont;
