import React from 'react';
import styled from '@emotion/styled';
import { themeGet } from 'styled-system';
import withProps from '../../../withProps';
import Portal from '@experiences-ui/shared/v2/components/Portal';

const Bar = withProps({
  'data-testid': 'PROGRESS_BAR',
})(styled('div')`
  height: 4px;
  background-image: linear-gradient(
    -90deg,
    ${themeGet('colors.primary')},
    #fff
  );
  z-index: ${themeGet('zIndices.popup')};
  position: fixed;
  top: 0;
  left: 0;
  width: 50%;
  animation: loading 2s linear infinite;
  @keyframes loading {
    0% {
      transform: translate(-100%);
    }
    100% {
      transform: translate(210%);
    }
  }
`);

const ProgressBar = () => (
  <Portal selector="progressBar-root">
    <Bar />
  </Portal>
);

export default ProgressBar;
