/* eslint-disable react/jsx-props-no-spreading */
import styled from '@emotion/styled';
import { Link as StyledLink } from '@qga/roo-ui/components';
import { color, fontSize, fontWeight, space, display } from 'styled-system';
import withProps from '@/components/withProps';
import Button from '@/components/Button';
import withLink from './withLink';

const ButtonLink = withProps({
  as: 'a',
})(Button);

const TextLink = styled(StyledLink)`
  font-weight: bold;

  ${display}
`;

const NakedLink = styled.a`
  text-decoration: none;
  cursor: pointer;
  color: inherit;

  ${color}
  ${fontSize}
  ${fontWeight} 
  ${space}
`;

const InlineLink = styled(StyledLink)`
  display: inline;
`;

const Link = {};

Link.Text = withLink(TextLink);
Link.Button = withLink(ButtonLink);
Link.Naked = withLink(NakedLink);
Link.Inline = withLink(InlineLink);

export default Link;
