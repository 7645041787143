import PropTypes from 'prop-types';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Box, Link } from '@qga/roo-ui/components';
import Alert from '@/components/Alert';
import withProps from '../withProps';
import message from '../../data/sitemessage.md';

const SmallParagraph = withProps({
  fontSize: 'sm',
})(Box);

const overrideRenderers = () => ({
  paragraph: SmallParagraph,
  link: Link,
});

export const SiteMessage = ({ markdown }) => {
  if (!markdown) {
    return null;
  }

  return (
    <Alert.Error>
      <ReactMarkdown source={markdown} renderers={overrideRenderers()} />
    </Alert.Error>
  );
};

SiteMessage.propTypes = {
  markdown: PropTypes.string.isRequired,
};

export default withProps({ markdown: message })(SiteMessage);
