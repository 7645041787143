import { Global } from '@emotion/core';
import PropTypes from 'prop-types';
import React from 'react';
import { Flex } from '@qga/roo-ui/components';
import FocusTrap from '@/components/FocusTrap';
import Portal from '@experiences-ui/shared/v2/components/Portal';
import ModalContext from './ModalContext';

const MODAL_WIDTH_MAP = {
  base: '500px',
  lg: '780px',
};

const Modal = ({ children, onClose, size, padding }) => (
  <Portal selector="portal-root">
    <ModalContext.Provider value={{ onClose }}>
      <Global
        styles={{
          body: {
            overflow: 'hidden',
          },
        }}
      />
      <FocusTrap>
        <Flex
          onClick={(e) => {
            e.preventDefault();
            if (onClose) onClose();
          }}
          alignItems={['stretch', null, 'center']}
          justifyContent="center"
          padding={padding}
          position="fixed"
          zIndex="popup"
          top="0"
          bottom="0"
          right="0"
          left="0"
          bg="rgba(34, 34, 34, 0.8)"
          data-testid="MODAL_BACKGROUND"
        >
          <Flex
            onClick={(event) => event.stopPropagation()}
            maxHeight="100%"
            flexDirection="column"
            width={[1, null, MODAL_WIDTH_MAP[size]]}
            bg="background.card"
            boxShadow="0px 8px 16px rgba(0, 0, 0, 0.12)"
            borderRadius={[null, null, 'md']}
          >
            {children}
          </Flex>
        </Flex>
      </FocusTrap>
    </ModalContext.Provider>
  </Portal>
);

Modal.defaultProps = {
  onClose: null,
  size: 'base',
  padding: [0, null, 12],
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func,
  size: PropTypes.oneOf(['base', 'lg']),
  padding: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
};

export default Modal;
