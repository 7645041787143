import { theme as baseTheme } from '@qga/roo-ui';
import fontSizes from './fontSizes';
import colors from './colors';

export const lineHeights = {
  tight: 1,
  normal: 1.25,
  loose: 1.5,
  large: 1.75,
};

export const fontFamily = 'Helvetica Neue, sans-serif';

// Font family for new JEL designs.
export const fontFamilyJEL = 'Roboto, Helvetica Neue, sans-serif';

export const headerFontFamily = 'Jetstar, Helvetica Neue, sans-serif';

const baseHeadingStyle = {
  fontFamily: headerFontFamily,
  fontWeight: baseTheme.fontWeights.bold,
  lineHeight: lineHeights.normal,
  color: colors.text.heading,
};

const baseBodyStyle = {
  fontFamily,
  lineHeight: lineHeights.loose,
  color: colors.text.body,
  fontSize: fontSizes.sm,
};

const textStyles = {
  heading: {
    ...baseHeadingStyle,
  },
  default: {
    ...baseBodyStyle,
  },
  paragraph: {
    ...baseBodyStyle,
  },
  roomy: {
    ...baseBodyStyle,
    lineHeight: lineHeights.large,
  },
  small: {
    ...baseBodyStyle,
    fontSize: fontSizes.xs,
    lineHeight: '24px',
  },
};

export default textStyles;
