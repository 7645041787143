import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@qga/roo-ui/components';
import styled from '@emotion/styled';
import { flex } from 'styled-system';

const StyledBody = styled(Box)`
  ${flex};
  overflow-y: auto;
`;

const Body = ({ children }) => (
  <StyledBody
    flex="1"
    pb={('16px', null, 3)}
    pt={['16px', null, 6]}
    px={['16px', null, 5]}
    py={['16px', null, null]}
    maxHeight={['100%', null, '760px']}
  >
    {children}
  </StyledBody>
);

Body.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Body;
