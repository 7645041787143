import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import UnexpectedErrorModalContext from '../../UnexpectedErrorModalContext';
import UnexpectedErrorModal from '../../UnexpectedErrorModal';

const Provider = ({ children }) => {
  const [unexpectedError, setUnexpectedError] = useState(null);

  useEffect(() => {
    const handler = (e) => {
      setUnexpectedError(e.reason);
    };

    window.addEventListener('unhandledrejection', handler);

    return () => {
      window.removeEventListener('unhandledrejection', handler);
    };
  }, []);

  return (
    <UnexpectedErrorModalContext.Provider value={setUnexpectedError}>
      {unexpectedError && !unexpectedError.skip && (
        <UnexpectedErrorModal onClose={() => setUnexpectedError()} />
      )}
      {children}
    </UnexpectedErrorModalContext.Provider>
  );
};

Provider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Provider;
