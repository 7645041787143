/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import NextLink from 'next/link';
import React from 'react';

const withLink =
  (Component) =>
  ({ href, as, shallow, external, prefetch = false, ...props }) => (
    <NextLink
      href={href}
      as={as}
      shallow={shallow}
      prefetch={prefetch}
      passHref
    >
      <Component
        {...(external && {
          target: '_blank',
          rel: 'noopener noreferrer nofollow external',
        })}
        {...props}
      />
    </NextLink>
  );

export default withLink;
